/* -----------------------------------------------------------------------------
 Component: GroupResultsChart
 ---------------------------------------------------------------------------- */

.group-results-chart {
  width: 100%;

  .chart-container {
    min-height: 200px;
  }

  .chart-wrapper {
    min-height: 250px;
  }

  .chart-data-not-ready {
    text-align: center;
    padding-top: 100px;
  }

  /* -----------------------------------------------------------------------------
   Heading Area
   ---------------------------------------------------------------------------- */

  .heading-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .heading-area .heading-text {
    font-family: CormorantMedium;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
  }

  /* -----------------------------------------------------------------------------
   Tooltip Content
   ---------------------------------------------------------------------------- */
  #tooltip-content-entry {
    padding: 4px 4px;
  }

  #tooltip-content-entry .title {
    font-style: italic;
    font-size: 12px;
    margin-bottom: 6px;
  }

  #tooltip-content-entry .title .month {
    margin-left: 4px;
  }

  #tooltip-content-entry .entry {
    font-size: 12px;
  }

  #tooltip-content-entry .entry .group-name {
    font-weight: 400;
    margin-right: 4px;
  }

  #tooltip-content-entry .entry .weight-value {
    font-weight: 900;
    font-size: 14px;
  }

  #tooltip-content-entry .entry .weight-unit {
    font-weight: 400;
    margin-left: 4px;
  }
}
