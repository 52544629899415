.qr-code {
    border: 8px solid black;
    border-radius: 10px;
}
  
.qr-code-scan-me-container {
    color: #FFFFFF;
    font-weight: bolder;
    text-transform: uppercase;
    background-color: black;
    border-radius: 9999px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    position: relative;
}
  
.svg-container {
    background-color: #FFFFFF;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black
}
  
.scan-me {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
  
.qr-code-container {
    width: min-content
}
  
.qr-code-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
