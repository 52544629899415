.share-button-wrapper {
    margin-top: 2px;
    margin-right: 2px;
    position:absolute;
    top:0;
    right:0;
    cursor: pointer;
}

.share-btn {
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    background: #FFFFFFB3 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0.75rem 1rem;
    cursor: pointer;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('../../../assets/icon-share-empty-gray.svg');
      background-size: contain;
    }

    &:hover > i {
      background-image: url('../../../assets/icon-share-filled-gray.svg');
    }
}

.group-summary-card .share-button-wrapper {
    margin-top: 15px;
    margin-right: 15px;
}

.menu {
    position: absolute;
    background: white;
    top: 100%;
    left: 0;
    margin-left: 10px;
    z-index: 2;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.281);
}

.menu ul {
    padding: 0px;
    padding-left: 0px !important;
    margin: 20px;
    list-style-type: none;
}

.menu button {
    margin: 5px;
}

.close-onclick {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}

.react-share__ShareButton:hover{
    opacity: 80%;
}

@media (max-device-width:600px) {
    .share-btn {
        padding: 0.5rem !important;
    }
}

@media (max-device-width:500px) {
    .menu {
        margin-left: -30px;
    }
}
