/* -----------------------------------------------------------------------------
 404 Page Styles
 ---------------------------------------------------------------------------- */
.page-404 {
  margin: 15px 0 0 0;
  text-align: center;

  h3 {
    color: #525252;
  }

  .back-button {
    margin: 23px 0 0 0;
  }
}
