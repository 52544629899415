/* -----------------------------------------------------------------------------
 GroupPage Styles
 ---------------------------------------------------------------------------- */
.group-page {
  background-repeat: no-repeat;
  background-attachment: local;

  .module-title {
    margin: 0 0 0.6rem 0;
    padding: 0 0 0 0;
    font-weight: 600;
    font-size: 20px;
  }

  .org-total-results-container {
    margin-left: 16px;
    margin-right: 16px;
    min-width: 0px;
    max-width: 480px;
  }

  .group-results-chart-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
    max-width: 480px;
  }

  @media screen and (min-width: 960px) {
    .group-results-chart-container {
      padding: .1rem;
    }
  }

  @media screen and (max-width: 960px) {
    .org-total-results-container {
      margin-top: 32px;
    }
    .group-results-chart-container {
      margin-top: 32px;
    }
    .org-total-results-container {
      margin-top: 36px;
    }
  }
}
