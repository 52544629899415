.group-page {
  .justify-center {
    justify-content: center;
  }

  .generated-total-weight-nbr {
    @media (max-width: 625px) {
      font-size: 24px;
    }

    @media (min-width: 626) and (max-width: 768px) {
      font-size: 27px;
    }

    @media (min-width: 769px) {
      font-size: 35px;
    }
  }

  @media (max-device-width:600px) {

    .group-summary-button img {
      width: 60px;
    }

    .button-container {
      width: 508px !important;
    }

    .floating-btns {
      padding: 0.5rem !important;
    }

    .floating-btn-label {
      font-size: 16px !important;
    }

    .dropdown-content {
      margin-top: 178px !important;
      width: 89px !important;
    }
  }

  .floating-btns {
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    background: #FFFFFFB3 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    margin: 0;
    position: relative;
    display: inline-block;
  }

  .floating-btns:hover {
    background: #0A509e 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
  }

  .floating-btns:hover > .floating-btn-label {
    color: #FFFFFF
  }

  .floating-btns:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }

  .floating-btn-label {
    font-size: 18px;
    font-family: Open Sans;
    color: #333333;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  #share-icon {
    margin-right: 0;
  }

  #share-icon-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .weight-icon {
    width: 20px;
    height: 20px;
  }

  .button-container-one {
    display: flex;
    align-items: center;
  }

  .floating-btn-text-label {
    margin: 0 8px;
  }

  .right-arrow-icon {
    width: 20px;
    height: 20px;
  }

  .info-icon {
    cursor: pointer;
    margin-left: 0.25rem;
  }

  .total-weight-icon-weight {
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/icon-weight-small-black.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .weight-dd:hover > .floating-btn-label > .total-weight-icon-weight {
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/icon-weight-small-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .total-weight-icon-small-down-arrow {
    width: 10px;
    height: 10px;
    background-image: url('../../../assets/icon-small-down-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .weight-dd:hover > .floating-btn-label > .total-weight-icon-small-down-arrow {
    width: 10px;
    height: 10px;
    background-image: url('../../../assets/icon-small-down-arrow-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .submit-weight-icon-arrow {
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/icon-submit-wgt-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .submit-weight:hover > .floating-btn-label > .submit-weight-icon-arrow {
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/icon-submit-wgt-arrow-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.modal-container-group-weight-submit-form {
  &.modal-page-content-container {
    position: relative;
  }

  .modal-close {
    right: 0.4rem;
    top: 0.5rem;
  }

  .modal-close > svg:hover {
    background-color: rgba(10, 80, 158, 0.1);
    border-radius: 50%;
  }
}
