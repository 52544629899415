.app-header {
  padding: 30px 0 24px 0;
  background-color: #ffffff;
  text-align: center;
}

.app-header .link-to-dropoffs {
  margin: 0 auto;
  width: 200px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: 8px 14px;
  cursor: pointer;
}

.app-header .link-to-dropoffs a {
  color: #333333;
  font-size: 1.08em;
  text-decoration: none;
}

.app-header .link-to-dropoffs:hover {
  background: #0A509e 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
}

.app-header .link-to-dropoffs:hover a {
  color: #ffffff;
}
