/* -----------------------------------------------------------------------------
 Component: CustomOrgChart
 ---------------------------------------------------------------------------- */

.custom-org-chart {
  width: 100%;

  .chart-title {
    margin: 0.6rem 0 0.6rem 0;
    padding: 0 0 0.6rem 0;
    font-weight: 600;
    font-size: 20px;
  }

  .chart-container {
    min-height: 200px;
  }

  .chart-wrapper {
    min-height: 250px;
  }

  .chart-data-not-ready {
    text-align: center;
    padding-top: 100px;
  }
}
