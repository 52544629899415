/* -----------------------------------------------------------------------------
 Component: OrganizationTotalResults
 ---------------------------------------------------------------------------- */
.org-total-results {
  width: 100%;

  .tooltip-logo {
    margin-left: 8px;
  }

  .total-results-split-container {
    display: flex;
    flex-direction: column;
    text-align: center;

    .total-results-left {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 1rem;
      width: 100%;

      > .module-title {
        display: none;
      }

      .total-weight-container {
        margin-bottom: 1.75rem;
      }

      .total-weight {
        font-size: 26px;
        font-weight: 900;
      }

      .total-weight-lbs {
        font-size: 24px;
        font-weight: 400;
      }
    }

    .total-results-right {
      padding-top: 1.25rem;
      text-align: center;
      width: 100%;
    }

    .subtext {
      font-size: 15px;
      margin: 0 0 1rem 0;
    }
  }

  .attribute-list {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-evenly;

    > li {
      display: flex;
      align-items: center;

      .icon {
        margin-right: .5rem;
        width: 1.25rem;
        height: 1.25rem;
      }

      .total-results-text {
        font-size: 14px;
      }

      .total-results-container > :not(:last-child) {
        margin-right: 4px;
      }

      .total-results-container > img {
        width: 20px;
        height: 20px;
      }
    }

    .tooltip-logo {
      width: 1rem;
      height: auto;
    }

  }

  @media (min-width: 601px) {
    > .module-title {
      display: none;
    }

    .total-results-split-container {
      flex-direction: row;
      text-align: left;

      .total-results-left {
        border-bottom: none;
        border-right: 1px solid #e5e5e5;
        padding-bottom: 0;
        padding-right: 1rem;

        > .module-title {
          display: block;
        }

        .total-weight-container > p {
          display: inline;
        }

        .subtext::after {
          content: ':';
          margin-right: 1rem;
        }
      }

      .total-results-right {
        padding-top: 0;
        padding-left: 1.25rem;
        max-width: 15rem;
      }
    }

    .attribute-list {
      justify-content: flex-start;

      > li:not(:last-of-type) {
        margin-right: 1.5rem;
      }
    }
  }

  @media (min-width: 891px) {
    .total-results-split-container .total-results-right {
      max-width: 21rem;
    }
  }

  @media (min-width: 1217px) {
    .total-results-split-container .total-results-right {
      max-width: none;
    }
  }
}
