/* Title */
.compost-guide {
    background-color: #00205b;
    text-align: center;
    padding-bottom: 110px;
    width: 100%;
    height:100%;
    position: relative;
}

.compost-guide h1 {
    padding: 30px 0px 30px;
    font-family: Cormorant;
    font-weight: bold;
    font-size: 40px;
    color: #ffffff;
}

/* Compost Guide Column Titles */
.compost-col {
    display: inline-block;
    margin: 0px 100px 0px;
}

.compost-col h3 {
    font-family: Cormorant, serif;
    font-size: 25px;
    font-weight: 600;
    text-align: left;
    padding-inline-start: 10px;
    margin-block-start: .5em;
    margin-block-end: 0em;
    background-repeat: no-repeat;
    padding-left: 45px;
    padding-bottom: 5px;
    display: block;
}

.check-icon {
    background-image: url('../../../assets/check.svg');
}

.x-icon {
    background-image: url('../../../assets/x-icon.svg');
}

@media only screen and (max-width: 576px), only screen and (max-device-width:576){
    .compost-col {
        display: inline-block;
        margin: 0px 0px 0px;
    }
}
/*Compost Guide Items*/
.compost-guide ul {
    list-style-type: none;
    text-align: left;
    padding-inline-start: 15px;
    float:left;
    padding-left: 45px;
}

.compost-guide li {
    font-family: CormorantInfant, sans-serif;
    font-size: 16px;
    line-height: 1.38;
    color: #e0e2e6;
}
