.city-page {
  /********** Containers **********/
  .page-content-container {
    display: flex;
    justify-content: center;
    font-family: 'Open Sans';
    padding: 0px 1.5rem
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap: 0.75rem;
    max-width: 1024px;
  }

  .btn-container {
    grid-column: span 8 / span 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .metrics {
    grid-column: span 2 / span 2;
  }

  .stats {
    grid-column: span 4 / span 4;
  }

  .total-metrics {
    grid-column: span 3 / span 3;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 1rem;
    color: #0A509E;
  }

  .total-results-split-container {
    display: flex;
  }

  .weight {
    flex-direction: column;
  }

  .dd-wrapper {
    display: inline;
    position: relative;
  }

  .dd-list {
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.281);
  }

  /********* Utility *********/

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 3em 3.75rem;
    color: #0A509E;
  }

  .chart-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 1.1em;
    color: #0A509E;
  }

  .space-x-2 > :not(:last-child) {
    margin-right: 0.5rem;
  }

  .p-2 {
    padding: 0.5rem;
  }

  .module-title {
    margin: 0 0 1rem;
    padding: 0 0 1rem 0;
    font-weight: 600;
    font-size: 20px;
  }

  #lbs {
    font-size: 20px;
    font-weight: 300;
  }

  .card-subtext {
    margin-top: 1rem;
    min-width: 165px;
    text-align: center;
  }

  .divider {
    width: 100%;
    background-color: #e5e5e5;
    border: 1px solid #e5e5e5;
    margin: 1rem 0;
  }

  .subtext {
    margin: 0 0 2.0rem 0;
    font-size: 14px;
    color: #547892
  }

  .total-weight-container {
    margin-bottom: 2.25rem;
  }

  .total-weight {
    font-size: 26px;
    font-weight: 900;
  }

  .total-weight-lbs {
    font-size: 24px;
    font-weight: 400;
  }

  .total-results-left {
    border-right: 1px solid #e5e5e5;
    padding-right: 1rem;
  }

  .total-results-right {
    padding-left: 1.25rem;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }


  .value, .title {
    text-align: center;
  }

  .value {
    font-size: 24px;
  }

  .title {
    font-size: 18px;
  }

  .metric-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  .dd-list > button {
    display: block;
  }

  .share-dd-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dd-item {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 24px;
    z-index: 2;
    list-style-type: none;
    cursor: pointer;
  }

  .dd-item:hover {
    background: #0A509e 0% 0% no-repeat padding-box;
    color: #FFFFFF !important;
  }

  .share-dd {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.25rem 0px;
    background: #F5F5F5 0% 0% no-repeat padding-box !important;
  }

  .share-dd:hover > .share-icon {
    color: #FFFFFFB3;
  }

  .weight-dd {
    min-width: 186.56px !important;
  }

  .share-icon {
    color: #333333;
  }

  @media (max-device-width:1216px) {
    .metrics {
      padding: 2.5rem;
    }

    .card-subtext {
      text-align: center;
      font-size: 14px;
    }
  }

  @media (max-device-width:890px) {
    .metrics {
      padding: 2rem;
    }

    .weight-dd {
      min-width: 144.5px !important;
    }

    .subtext {
      margin: 0 0 1rem 0;
    }

    .card-subtext {
      text-align: center;
      font-size: 14px;
    }

    .chart {
      grid-column: span 8 / span 8;
    }

    .total-metrics {
      grid-column: span 8 / span 8;
    }

    .total-results-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .total-results-left {
      width: 100%;
    }
  }

  @media (max-device-width:600px) {
    .page-content-container {
      display: flex;
      justify-content: center;
      font-family: 'Open Sans';
      padding: 0px 0.75rem
    }

    .grid-container {
      gap: 0.25rem;
    }

    .card {
      padding: 0.5rem;
    }

    .metrics {
      grid-column: span 8 / span 8;
      justify-content: center;
    }

    .chart {
      grid-column: span 8 / span 8;
    }

    .total-metrics {
      grid-column: span 8 / span 8;
    }

    .weight {
      border-radius: 8px 8px 0px 0px;
    }

    .metric-card-0 {
      border-radius: 0px;
    }

    .metric-card-1 {
      border-radius: 0px;
    }

    .metric-card-2 {
      border-radius: 0px 0px 8px 8px;
    }

    .group-summary-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5.5rem
    }

    .group-summary-button > .metric-img {
      margin-right: 12px;
    }

    .card-subtext {
      margin-top: 0.25rem;
    }

    #lbs {
      font-size: 18px;
    }

    .value, title {
      text-align: left;
    }

    .value {
      font-size: 24px;
    }

    .title {
      font-size: 14px;
      min-width: 140px;
      text-align: left;
    }
  }
}
