.modal-container {
  overflow: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
  border-radius: 8px;

  &.modal-page-content-container {
    max-width: 350px;
    min-width: 235px;
    /* width: 45vw; */
    vertical-align: middle;
    text-align: center;
    padding: 3.5rem 3.5rem;
  }

  .modal-text {
    color: #547892 !important;
    font: normal normal normal 16px/22px 'Open Sans';
    padding-top: 12px;
  }

  .modal-close {
    cursor: pointer;
    color: rgb(10, 80, 158);
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .modal-close > svg:hover {
    background-color: rgba(10, 80, 158, 0.1);
    border-radius: 50%;
  }

  .modal-title {
    font-family: Cormorant;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: -40px -25px 0px -25px;
    padding: 40px 0px 12px 0px;
    text-transform: uppercase;
  }

  @media (max-device-width:600px) {
    .modal-title {
      font-size: 36px !important;
    }
  }
}
