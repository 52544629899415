.page-title .title {
  margin-top: 40px;
  font-family: Cormorant;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.page-title .sub-title {
  font-family: CormorantInfant;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.page-title .divider {
  width: 299px;
  height: 1px;
  margin: 12px 37px 13px 33px;
  border: solid 1px #ffffff;
}

hr {
  width: 250px;
}
