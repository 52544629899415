/* -----------------------------------------------------------------------------
 Component: OrganizationAssociations
 ---------------------------------------------------------------------------- */

.group-page .org-associations,
.org-associations {
  width: 100%;

  .subtext {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: .9375rem;
    text-align: left;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .weight {
    --line-height: 1.5rem;
    text-align: center;

    p {
      font-size: 1.625rem;
      margin-top: 0;
      margin-bottom: 1rem;
      line-height: var(--line-height);
    }

    i {
      display: inline-block;
      margin-right: .25rem;
      width: var(--line-height);
      height: var(--line-height);
      vertical-align: bottom;
      background-image: url('../../../assets/icon-weight-small-blue.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  ul {
    display: grid;
    margin: 0;
    padding: 0;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);

    li {
      --line-height: 1.75rem;
      line-height: var(--line-height);

      .count-icon {
        width: var(--line-height);
        height: var(--line-height);
        vertical-align: bottom;
        margin-right: .5rem;
      }
    }
  }

  @media (min-width: 601px) {
    .module-title, .subtext {
      display: inline-block;
    }

    .module-title {
      margin-right: 1rem;
    }

    .container {
      flex-direction: row;
      margin-top: 0.75rem;

      .weight {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        text-align: left;

        p {
          margin-bottom: 0.5rem;
        }
      }

      ul {
        padding: .25rem 0 0 0;
        flex-grow: 2;
        grid-template-columns: repeat(3, 1fr);

        li {
          margin-bottom: .5rem;
        }
      }
    }
  }

  @media (min-width: 891px) {
    .container ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: 1217px) {
    .container ul {
      flex-grow: 3;
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
