/* -----------------------------------------------------------------------------
 Main HTML
 ---------------------------------------------------------------------------- */
html, body {
  margin: 0px;
  padding: 0px;
}

/* -----------------------------------------------------------------------------
 Footer Area
 ---------------------------------------------------------------------------- */
.footer-area {
  padding-bottom: 1.2rem;
}

/* -----------------------------------------------------------------------------
 Fonts
 ---------------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cormorant&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@font-face {
  font-family: "Cormorant";
  src: local("MyFont"),
    url(../assets/fonts/CormorantInfant.ttf) format("ttf");
}

@font-face {
  font-family: "CormorantMedium";
  src: local("MyFont"),
    url(../assets/fonts/Cormorant-Medium.ttf) format("ttf");
}

@font-face {
  font-family: "CormorantInfant";
  src: local("MyFont"),
    url(../assets/fonts/Cormorant-Light.ttf) format("ttf");
}

* {
    font-family: 'Open Sans' !important;
}
