.progress-circle-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: transparent;

    .circle-bg {
        stroke: #dae5f0;
        fill: none;
    }

    .circle-progress {
        stroke: #0A509E;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition: 0.6s;
        fill: none;
    }

    .circle-text {
        font-size: 16px;
        font-weight: bold;
        fill: #0A509E;
    }
}
