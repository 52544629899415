/* -----------------------------------------------------------------------------
 System Down Page Styles
 ---------------------------------------------------------------------------- */
.page-system-down {
  margin: 15px 0 0 0;
  text-align: center;

  h3 {
    margin-top: 3.1rem;
    color: #525252;
  }

  .back-button {
    margin: 23px 0 0 0;
  }
}
