.powered-by-moonshot {
  padding: 1.2rem 0 0 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;

  p {
    margin: 20px 0px 13px 0;
    padding-right: 9px;
    font-family: CormorantInfant, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #aaaaaa;
  }
}
