.dropoff-form-container {
  min-width: 375px;
  text-align: center;

  .dropoff-form {
    width: 100%;
    display: inline-block;
  }

  .module-title {
    color: #0a509e;
  }

  .col {
    text-align: center;
    flex: 1;
  }

  form {
    display: block;
  }

  .input-text {
    text-align: center;
    width: 100%;
  }

  .input-label {
    margin-top: 0.2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .weight-submission-page-title {
    color: #0a509e;
    padding-top: 0.5rem;
  }

  .thank-you {
    font-size: 1rem;
    text-align: left;
  }

  .dropoff-submit-button {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 20px!important;
    font-weight: bold!important;
    padding: 12px 23px !important;
    margin-bottom: 1rem !important;
    text-transform: none !important;
  }

  .location-frontload {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #666666;
  }

  .location-frontload-name {
    color: #333333;
    font-size: 14px;
  }

  .customer-data {
    padding-bottom: 2rem;
    font-size: 1.5rem;
    color: #0a509e;
  }

  .dropoff-question {
    font-family: CormorantInfant;
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
  }

  .location-selector-container {
    margin-bottom: 1.5rem;
    text-align: left;
  }

  .location-selector {
    width: 100%;
  }

  .separator {
    display: flex;
    align-items: center;
  }

  .divider-text {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.54);
  }

  #email-address {
    margin-top: 0.2rem;
    text-align: center;
  }

  .lookup-error {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    background: rgb(255, 111, 136);
    color: white;
    animation: shake 0.8s;
    margin-bottom: 1rem;
  }

  .lookup-success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    background: rgb(171, 210, 100);
    color: white;
    animation: shake 0.8s;
    margin-bottom: 1rem;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(25px);
    }

    50% {
      transform: translateX(-25px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  .weight-submission-col {
    padding: 2rem;
  }

  .ws-head {
    background: #779dcc 0% 0% no-repeat padding-box;
  }

  .ws-sub-title {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.5rem 0;
    color: white;
    font-size: 32px;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
  }

  .ws-sub-title-2 {
    text-align: left;
    font-family: "Open Sans", sans-serif;
    padding-top: 1rem;
    color: white;
  }

  .text-underline {
    text-decoration: underline;
  }

  .sm-hr {
    border: none;
    height: 1px;
    color: #fff;
    background-color: #fff;
  }

  .ws-name {
    font-size: 22px;
    font-family: "Open Sans", sans-serif;
    color: white;
    text-align: left;
    margin: 1.5rem 0;
  }

  .dropoff-form > :not(:last-child) {
    margin-bottom: 1rem;
  }

  .dropoff-form > :last-child {
    margin-top: 1rem;
  }

  .submit-success-weight {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
  }

  .submit-success-weight-message {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
  }

  .submit-success-weight-link {
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    font-size: 14px;
    color: #0A509E;
    text-decoration: underline;
    font-weight: bold;
  }

  .bin-toggle-button {
    font-weight: bold;
    text-transform: unset !important;
    width: 155px;
  }

  .weight-submission-text {
    font-family: 'Open sans' !important;
  }

  /* -----------------------------------------------------------------------------
   Mobile view
   ---------------------------------------------------------------------------- */

  @media screen and (min-width: 768px ) {
    .ws-head {
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;
    }
  }
}
